.mypic-img {
  height: 690px;
  padding-left: 34em;
}

.header-color {
  background-color: white;
}

.title-icon {
  height: 40px;
}

.intro-grid {
  /*text-align: center; 
  position: absoulte;
  height: 100%;*/
  padding-top: 5em;
  position: default;
  top: 0;
  left: 0;
  width: 100%;
}

.container {
  position: relative;
  text-align: center;
}

.first-row {
  /* Bottom left text */
  font-size: 80px;
  font-weight: 700;
  position: absolute;
  bottom: 100px;
  left: 85px;
  font-family: "Red Hat Mono", monospace;
}

.second-row {
  /* Bottom left text */
  font-size: 24px;
  position: absolute;
  bottom: 75px;
  left: 90px;
}

.social-links {
  position: absolute;
  bottom: 30px;
  left: 75px;
}

.social-links a {
  color: black;
  font-size: 3em;
  padding: 15px;
}

.intro-grid h1 {
  font-size: 80px;
  color: white;
  font-weight: 700;
  font-family: "Red Hat Mono", monospace;
}

.intro-grid p {
  font-size: 24px;
  color: white;
  padding: 0.5em;
  font-family: "Red Hat Text", sans-serif;
  /*animation: animated-text 3s steps(29, end) 1s 1 normal both;*/
}

.text-wrapper {
  text-align: center;
  max-width: 100%;
}

.text {
  font-size: 15px;
  color: lightslategray;
  padding: 0.5em;
  font-family: "Red Hat Text", sans-serif;
}

.projects-grid {
  padding-top: 2em;
  padding-left: 4em;
  padding-right: 4em;
}

.projects .card {
  min-width: 85%;
  margin-bottom: 50px !important;
}

.build18pic-img {
  height: 300px;
  padding-top: 5em;
  padding-left: 3em;
}

.aboutme-text {
  margin-top: 40px;
}

.aboutme-text p {
  font-size: 24px;
  color: black;
  /*padding: 3em;*/
  font-family: "Red Hat Text", sans-serif;
  line-height: 125%;
  padding-left: 3em;
}

.aboutme-text h1 {
  /*padding-top: 1em;*/
  font-size: 70px;
  color: black;
  font-family: "Red Hat Mono", monospace;
}

.aboutme-text h2 {
  font-size: 30px;
  font-family: "Red Hat Mono", monospace;
}

.campusLife-header {
  /*padding-top: 1em;*/
  margin-left: auto;
  margin-right: auto;
  width: 8em;
  font-size: 40px;
  color: #2f363d;
  font-family: "Red Hat Text", sans-serif;
}

.interests-header {
  /*padding-top: 1em;*/
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 16em;
  font-size: 40px;
  color: #2f363d;
  font-family: "Red Hat Mono", monospace;
}

.hack-h,
.scabs-h,
.swim-h {
  padding-top: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 6em;
  font-size: 30px;
  color: #4b5866;
  font-family: "Red Hat Text", sans-serif;
}

.hack-p {
  font-size: 24px;
  color: #4b5866;
  font-family: "Red Hat Text", sans-serif;
  padding-top: 3em;
}
.interests {
  font-size: 24px;
  color: #4b5866;
  font-family: "Red Hat Text", sans-serif;
  margin-left: auto;
  margin-right: auto;
  width: 50em;
}
.scabs-p {
  font-size: 24px;
  color: #4b5866;
  font-family: "Red Hat Text", sans-serif;
  padding-top: 2em;
  text-align: "right";
  width: 100%;
  display: block;
}

.littlewomen-pic,
.swim-pic,
.thacks-pic {
  height: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

small {
  font-family: "Red Hat Text", sans-serif;
  color: #4b5866;
  font-style: italic;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

hr {
  border: 0;
  height: 1px;
  display: block;
  width: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(87, 87, 87, 0.527),
    rgba(0, 0, 0, 0)
  );
}

.demo-img {
  height: 400px;
  padding-top: 2.5em;
  padding-left: 2.5em;
}

.project-text {
  margin-left: 40px;
}

.project-text h1 {
  font-size: 66px;
  color: black;
  font-family: "Red Hat Mono", monospace;
}

.project-text p {
  font-size: 20px;
  color: black;
  padding: 0.5em;
  font-family: "Red Hat Text", sans-serif;
  overflow-wrap: break-word;
}

.fa {
  font-family: "FontAwesome" !important;
}

.project-links {
  display: block;
}

.project-links a {
  color: black;
  font-size: 3em;
  padding: 15px;
}

.demo-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.demo-btn .button {
  width: 300px;
  font-size: 2rem !important;
}

@media (max-width: 700px) {
  .project-text h1 {
    font-size: 40px;
  }
  .aboutme-text p {
    padding-left: 0em;
  }
  .banner-text p {
    font-size: 18px;
  }
  .mypic-img,
  .build18pic-img,
  .spotifyplaylist-img,
  .littlewomen-pic,
  .swim-pic,
  .thacks-pic {
    height: 220px;
    padding-bottom: 2em;
    padding-left: 0em;
  }
}

.aboutme-img {
  height: 500px;
  margin-left: 90px;
  margin-bottom: 50px;
}

.pic-grid img {
  padding: 20px;
}

.video-wrapper {
  align-content: center;
  display: flex;
  justify-content: center;
}

.videoTag {
  vertical-align: center;
  margin-top: 100px;
}

.poster-img {
  height: 500px;
  padding-top: 1em;
  padding-left: 9em;
  padding-bottom: 2em;
}
